import { createRouter, createWebHistory } from "vue-router";
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: "/",
    redirect: "/login",
    component: () => import("@/layouts/AuthLayout"),
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("../views/auth/Login.vue"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Login",
        },
      },
      {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import("../views/auth/ForgotPassword.vue"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Forgot Password",
        },
      },
      {
        path: "/account-confirm/:token",
        name: "Account Confirmation",
        component: () => import("../views/auth/AccountConfirmation.vue"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Account Confirmation",
        },
      },
      {
        path: "/reset-password/:token",
        name: "Reset Password",
        component: () => import("../views/auth/ResetPassword.vue"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Reset Password",
        },
      },
      {
        path: "/otp",
        name: "OTP",
        component: () => import("../views/auth/Otp.vue"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | OTP",
        },
      },
      {
        path: "/email-notification",
        name: "Email Notification",
        component: () => import("../views/auth/EmailNotification"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Email Notification",
        },
      },
    ],
  },
  {
    path: "/dashboard",
    component: () => import("@/layouts/DashboardLayout"),
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("../views/Dashboard.vue"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Dashboard",
        },
      },
      {
        path: "/notifications",
        name: "Notifications",
        component: () => import("../views/Notifications.vue"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Notification",
        },
      },
      {
        path: "/ticket-notification",
        name: "NCC Notifications",
        component: () => import("../views/NCCNotification.vue"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Notification",
        },
      },
      {
        path: "/ticket-notifications",
        name: "OCCSP Notifications",
        component: () => import("../views/OCCSPNotification.vue"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Notification",
        },
      },
      {
        path: "/dashboard/change-password",
        name: "Change Password",
        component: () => import("../views/ChangePassword"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | User Profile",
        },
      },
      {
        path: "/dashboard/profile",
        name: "Profile",
        component: () => import("../views/Profile.vue"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Profile",
        },
      },
      {
        path: "/dashboard/user/:id",
        name: "UserProfile",
        component: () => import("../views/user_management/UserProfile"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | User Profile",
        },
      },
      {
        path: "/dashboard/user/create",
        name: "Create User",
        component: () => import("../views/user_management/CreateUser"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | User Profile",
        },
      },
      {
        path: "/dashboard/user/edit/:id",
        name: "Update User",
        component: () => import("../views/user_management/EditUser"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | User Profile",
        },
      },
      {
        path: "/dashboard/user",
        name: "User List",
        component: () => import("../views/user_management/ListUser"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | User List",
        },
      },
      {
        path: "/dashboard/complaint/create",
        name: "CreateComplaint ",
        component: () => import("../views/complaint/CreateComplaint"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | User List",
        },
      },
      {
        path: "/dashboard/complaint",
        name: "Complaint List",
        component: () => import("../views/complaint/ComplaintList"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Complaint List",
        },
      },
      {
        path: "/dashboard/complaint/:id",
        name: "ViewComplaint",
        component: () => import("../views/complaint/ViewComplaint"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | User List",
        },
      },
      {
        path: "/dashboard/data-upload",
        name: "Upload Complaint",
        component: () => import("../views/complaint/BulkUpload"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Bulk Upload",
        },
      },
      {
        path: "/dashboard/contact",
        name: "Contact List",
        component: () => import("../views/contact-management/ContactList"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Contact List",
        },
      },
      {
        path: "/dashboard/contact/create",
        name: "Create Contact ",
        component: () => import("../views/contact-management/CreateContact"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Create Contact",
        },
      },
      {
        path: "/dashboard/contact/:id",
        name: "ViewContact",
        component: () => import("../views/contact-management/ViewContact"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | View Contact",
        },
      },
      {
        path: "/dashboard/contact/edit/:id",
        name: "EditContact ",
        component: () => import("../views/contact-management/CreateContact"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Edit Contact",
        },
      },
      {
        path: "/settings",
        name: "Settings",
        component: () => import("../views/settings/Settings"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Settings",
        },
      },
      {
        path: "/settings/view-sp",
        name: "Create Pension Fund Administrator",
        component: () => import("../views/settings/ViewSP"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Create Pension Fund Administrator",
        },
      },
      {
        path: "/reports",
        name: "Reports",
        component: () => import("@/views/reports/Reports"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Reports",
        },
      },
      {
        path: "/reports/complaint-by-state",
        name: "Complaints By State",
        component: () => import("../views/reports/ComplaintsByStates"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Complaints",
        },
      },
      {
        path: "/reports/complaint-by-category",
        name: "Complaints By Category",
        component: () => import("../views/reports/ComplaintsByCategory"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Complaints By Category",
        },
      },
      {
        path: "/reports/complaint-by-geozone",
        name: "Complaints By GeoPolitical Zone",
        component: () => import("../views/reports/ComplaintsByGeoZone"),
        meta: {
          disableIfLoggedIn: true,
          title:
            "PENCOM CRM | Complaints By GeoPolitical Zone",
        },
      },
      {
        path: "/reports/complaint-by-source",
        name: "Complaints By Source",
        component: () => import("../views/reports/ComplaintsBySource"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Complaints By Source",
        },
      },
      {
        path: "/reports/complaint-by-sp",
        name: "Complaints By Pension Fund Administrator",
        component: () => import("../views/reports/ComplaintsBySP"),
        meta: {
          disableIfLoggedIn: true,
          title:
            "PENCOM CRM | Complaints By Pension Fund Administrator",
        },
      },
      {
        path: "/reports/complaint-by-status",
        name: "Complaints By Status",
        component: () => import("../views/reports/ComplaintsByStatus"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Complaints By Status",
        },
      },{
        path: "/reports/complaint-by-sla-breach",
        name: "Complaints By SLA Breach",
        component: () => import("../views/reports/ComplaintsByBreachedStatus"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Complaints By SLA Breach",
        },
      },
      {
        path: "/reports/complaint-by-language",
        name: "Complaints By Language",
        component: () => import("../views/reports/ComplaintsByLanguage"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Complaints By Language",
        },
      },
      {
        path: "/reports/case-type",
        name: "Complaints By Language",
        component: () => import("../views/reports/CaseType"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Case Type",
        },
      },
      {
        path: "/sla",
        name: "Service Level Agreements",
        component: () => import("../views/SLA"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Service Level Agreements",
        },
      },
      {
        path: "/reports/complaint-by-state-sp",
        name: "Complaints By State SP",
        component: () => import("../views/reports/ComplaintsByStatesSPs"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Complaints",
        },
      },
      {
        path: "/reports/complaint-by-geozone-sp",
        name: "Complaints By GeoPolitical Zone SP",
        component: () => import("../views/reports/ComplaintsByGeoZoneSPs"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Complaints",
        },
      },
      {
        path: "/reports/complaint-by-status-sp",
        name: "Complaints By Status SP",
        component: () => import("../views/reports/ComplaintsByStatusSPs"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Complaints",
        },
      },
      {
        path: "/reports/complaint-by-category-sp",
        name: "Complaints By Category SP",
        component: () => import("../views/reports/ComplaintsByCategorySPs"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Complaints",
        },
      },
      {
        path: "/reports/complaint-by-state-occsp",
        name: "Complaints By State OCCSP",
        component: () => import("../views/reports/ComplaintsByStatesOCCSP"),
        meta: {
          disableIfLoggedIn: true,
          title:
            "PENCOM CRM | Complaints by State OCCSP",
        },
      },
      {
        path: "/reports/complaint-by-category-occsp",
        name: "Complaints By Category OCCSP",
        component: () => import("../views/reports/ComplaintsByCategoryOCCSP"),
        meta: {
          disableIfLoggedIn: true,
          title:
            "PENCOM CRM | Complaints By Category OCCSP",
        },
      },
      {
        path: "/reports/complaint-by-geozone-occsp",
        name: "Complaints By GeoPolitical Zone OCCSP",
        component: () => import("../views/reports/ComplaintsByGeoZoneOCCSP"),
        meta: {
          disableIfLoggedIn: true,
          title:
            "PENCOM CRM | Complaints By GeoPolitical Zone OCCSP",
        },
      },
      {
        path: "/reports/complaint-by-sp-occsp",
        name: "Complaints By Pension Fund Administrator OCCSP",
        component: () => import("../views/reports/ComplaintsBySPOCCSP"),
        meta: {
          disableIfLoggedIn: true,
          title:
            "PENCOM CRM | Complaints By Pension Fund Administrator OCCSP",
        },
      },
      {
        path: "/reports/complaint-by-status-occsp",
        name: "Complaints By Status OCCSP",
        component: () => import("../views/reports/ComplaintsByStatusOCCSP"),
        meta: {
          disableIfLoggedIn: true,
          title:
            "PENCOM CRM | Complaints By Status OCCSP",
        },
      },
      {
        path: "/reports/complaint-by-language-occsp",
        name: "Complaints By Language OCCSP",
        component: () => import("../views/reports/ComplaintsByLanguageOCCSP"),
        meta: {
          disableIfLoggedIn: true,
          title:
            "PENCOM CRM | Complaints By Language OCCSP",
        },
      },
      {
        path: "/social-media",
        name: "Social Media",
        component: () => import("@/views/social-media/SocialMedia"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Social Media",
        },
      },
      {
        path: "/audit",
        name: "Audit-Trail",
        component: () => import("@/views/audit-trail/AuditTrail"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | Audit Trail",
        },
      },
      {
        path: "/404",
        name: "404",
        component: () => import("@/views/404"),
        meta: {
          disableIfLoggedIn: true,
          title: "PENCOM CRM | 404",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)",
    name: "not-found",
    component: () => import("@/views/404"),
    meta: {
      disableIfLoggedIn: true,
      title: "PENCOM CRM | 404",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(to);
  document.title = to.meta.title;
  next();
});

export default router;
